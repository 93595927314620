<clr-main-container>
    <ng-container *ngFor="let alert of alerts;">
        <clr-alert [clrAlertType]="alert.type || 'danger'">
            <clr-alert-item>
                <span class="alert-text">
                    {{ alert.text || alert.type }}
                </span>
            </clr-alert-item>
        </clr-alert>
    </ng-container>

    <clr-header>


        <div class="branding">
            <a [routerLink]="['/']" routerLinkActive="active" class="nav-link">
                <img src="/assets/img/mbi-logo.png" class="logo" />
                <span class="title">Mortgage <span>Buyers</span></span>
            </a>
        </div>
        <div class="header-nav" [clr-nav-level]="1">
            <a [routerLink]="['/quote']" routerLinkActive="active" class="nav-link">Get Quote</a>
            <a [routerLink]="['/note-info']" routerLinkActive="active" class="nav-link">Note Info</a>
            <a [routerLink]="['/company']" routerLinkActive="active" class="nav-link">Company</a>
            <a [routerLink]="['/contact-us']" routerLinkActive="active" class="nav-link">Contact Us</a>

        </div>

        <div class="header-actions">
            <span class="nav-link">Call {{ ws.config.company.phone[0].number }} </span>
        </div>


    </clr-header>
    <!--nav class="subnav" [clr-nav-level]="2">
        <ul class="nav">
            <li class="nav-item" *ngFor="let cat of nav">
                <clr-dropdown>
                    <button class="btn btn-outline-primary btn-swm-nav" clrDropdownTrigger>
                        {{ cat.name }}
                        <clr-icon shape="caret down"></clr-icon>
                    </button>
                    <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
                        <label class="dropdown-header" aria-hidden="true">Category</label>
                        <div aria-label="Dropdown header Action 1" clrDropdownItem>
                            <img src="//cdn.southwestmedical.com{{cat.picture_url}}?w=40&h=40&fit=fill" width="40"
                                heigth="40" style="margin:5px;float:left;">
                            <a href="/category?category_id={{cat.category_id}}" routerLinkActive="active"
                                class="nav-link">
                                {{ cat.name }} Category
                            </a>
                        </div>

                        <div class="dropdown-divider" role="separator" aria-hidden="true"></div>

                        <clr-dropdown *ngFor="let scat of getObjectValues(cat['category-categories'])">
                            <button *ngIf="getObjectValues(scat['category-categories'])?.length == 0" clrDropdownItem>
                                <a href="/category?category_id={{scat.category_id}}" routerLinkActive="active"
                                    xclass="nav-link">
                                    {{ scat.name }}
                                </a>
                            </button>
                            <button *ngIf="getObjectValues(scat['category-categories'])?.length > 0"
                                clrDropdownTrigger>{{ scat.name }}</button>
                            <clr-dropdown-menu *ngIf="getObjectValues(scat['category-categories'])?.length > 0">
                                <button clrDropdownItem>
                                    <a href="/category?category_id={{scat.category_id}}" routerLinkActive="active"
                                        xclass="nav-link">
                                        {{ scat.name }} Link
                                    </a>
                                </button>
                                <clr-dropdown *ngFor="let sscat of getObjectValues(scat['category-categories'])">
                                    <button *ngIf="getObjectValues(sscat['category-categories'])?.length == 0"
                                        clrDropdownItem>
                                        <a href="/category?category_id={{sscat.category_id}}" routerLinkActive="active"
                                            xclass="nav-link">
                                            {{ sscat.name }}
                                        </a>
                                    </button>
                                    <button *ngIf="getObjectValues(sscat['category-categories'])?.length > 0"
                                        clrDropdownTrigger>{{ sscat.name }}</button>
                                    <clr-dropdown-menu *ngIf="getObjectValues(sscat['category-categories'])?.length > 0"
                                        clrPosition="left-top">
                                        <button clrDropdownItem>{{ sscat.name }} Link</button>
                                        <clr-dropdown
                                            *ngFor="let ssscat of getObjectValues(sscat['category-categories'])">
                                            <button *ngIf="getObjectValues(ssscat['category-categories'])?.length == 0"
                                                clrDropdownItem>
                                                <a href="/category?category_id={{ssscat.category_id}}"
                                                    routerLinkActive="active" xclass="nav-link">
                                                    {{ ssscat.name }}
                                                </a>
                                            </button>
                                            <button *ngIf="getObjectValues(ssscat['category-categories'])?.length > 0"
                                                clrDropdownTrigger>{{ ssscat.name }}</button>
                                        </clr-dropdown>
                                    </clr-dropdown-menu>
                                </clr-dropdown>
                            </clr-dropdown-menu>
                        </clr-dropdown>
                    </clr-dropdown-menu>
                </clr-dropdown>




            </li>
        </ul>
    </nav-->

    <div class="content-container">
        <div class="content-area">
            <router-outlet></router-outlet>
            <hr>
            <footer>
                <div class="center">

                    <!--div class="clr-row">
                        <div class="clr-col-12">
                            <div class="card card-secondary">
                                <div class="card-block"-->
                                    <h3>We would like to advise you on how you can set up your note for the best resale
                                        value. <a [routerLink]="['/contact-us']" routerLinkActive="active">Call us for questions</a>.</h3>
                                <!--/div>
                            </div>
                        </div>
                    </div-->
                    <h2>NO OBLIGATION <a [routerLink]="['/quote']" routerLinkActive="active">QUOTE</a></h2>
                    <h6>NO CLOSING COSTS OR HASSLES - CALL {{ ws.config.company.phone[0].number }}!</h6>
                    <small>(if you prefer, the entire transcation can be handled by mail or email.)</small><br>
                    <div style="margin-top:20px;"><small>{{ ws.config.company.legalName }}  {{ ws.config.company.address[0].location }}
                        <br>Toll Free: {{ ws.config.company.phone[0].number }} &bull; Work: {{ ws.config.company.phone[1].number }} &bull; Fax: {{ ws.config.company.phone[2].number }}
                    </small></div>
                </div>
            </footer>

        </div>

        <nav class="sidenav" [clr-nav-level]="2" *ngIf="subnav">
            <section class="sidenav-content" *ngFor="let cat of subnav">
                <a *ngIf="cat.href; else elseNoHref" href="..." class="nav-link {{cat.active?'active':''}}">
                    {{ cat.text }}
                </a>
                <ng-template #elseNoHref>
                    <section class="nav-group {{cat.collapsible?'collapsible':''}}">
                        <input id="{{cat.text}}" type="checkbox">
                        <label for="{{cat.text}}">{{ cat.text }}</label>
                        <ul class="nav-list" *ngFor="let link of cat.links">
                            <li><a class="nav-link {{link.active?'active':''}}">{{link.text}}</a></li>
                        </ul>
                    </section>
                </ng-template>
            </section>
        </nav>



    </div>
</clr-main-container>