import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';
import { ReactiveFormsModule } from '@angular/forms';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';


const firebaseConfig = {
  apiKey: "AIzaSyDVGoxNXc_sHDhjFT_ynAgqnqm9Nzybdd8",
  authDomain: "mortgagebuyersinc-6b9ea.firebaseapp.com",
  databaseURL: "https://mortgagebuyersinc-6b9ea.firebaseio.com",
  projectId: "mortgagebuyersinc-6b9ea",
  storageBucket: "mortgagebuyersinc-6b9ea.appspot.com",
  messagingSenderId: "873356162084",
  appId: "1:873356162084:web:ea35cd9a26edeab79a14b1",
  measurementId: "G-FPY3ZJYCFT"
};


@NgModule({
  imports: [
    CommonModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
  ],
  exports: [ 
    ClarityModule, 
    ReactiveFormsModule,
  ]
})
export class MbiModule {}
