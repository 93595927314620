import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';


const childrenRoutes: Routes = [
  { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
  { path: 'quote',  loadChildren: () => import('./pages/quote/quote.module').then(m => m.QuoteModule) },
  { path: 'note-info',  loadChildren: () => import('./pages/note-info/note-info.module').then(m => m.NoteInfoModule) },
  { path: 'company',  loadChildren: () => import('./pages/company/company.module').then(m => m.CompanyModule) },
  { path: 'contact-us',  loadChildren: () => import('./pages/contact-us/contact-us.module').then(m => m.ContactUsModule) },

  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  // FORWARDING BELOW
  { path: 'get-a-quote', redirectTo: 'quote', pathMatch: 'full' },  
  { path: 'about-us', redirectTo: 'company', pathMatch: 'full' },
  { path: 'about-us/contact-us', redirectTo: 'contact-us', pathMatch: 'full' },
  { path: 'about-us/privacy-policy', redirectTo: 'company', pathMatch: 'full' },
/*
  { matcher: function(url: UrlSegment[]){ 
      return url[0].path.startsWith('about-us') ? ({consumed: url}) : null; 
    }, redirectTo: 'company' 
  },*/
  { matcher: function(url: UrlSegment[]){ 
    return url.length > 1 && url[0].path === 'note-info' ? ({consumed: url}) : null; 
    }, redirectTo: 'note-info' 
  },
  { path: '**', loadChildren: () => import('./pages/not-found/not-found.module').then(m => m.NotFoundModule), pathMatch: 'full' },
];

const routes: Routes = [
//  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
  { path: '', component: LayoutComponent, children: childrenRoutes },
//  { path: '**', component: NotFoundComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }