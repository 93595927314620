import { Injectable } from '@angular/core';
import { WindowService } from './window.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WebsiteService {
  private _config: any;

  private globalConfig = {
    timezone: 'America/Phoenix',
    social: {
      twitter: {
        accessToken: '2xxxxxxxxx8-NxxxxxxxxxxxxxxxxxxxxQG',
        consumerSecret: '9QxxxxxxxxxxxxxxxxxxxxxZIIxxxxxxxxxxxxxxxxxxxAK',
        consumerKey: 'hx4xxxxxxxRgxxxxxxxxC2',
        accessTokenSecret: 'zexxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxPd',
        mode: 'live'
      }
    },
    company: {
      email: [
        {
          default: true,
          address: 'info@mortgagebuyersinc.com',
          label: 'Main'
        },
        {
          address: 'sales@mortgagebuyersinc.com',
          label: 'Sales'
        }
      ],
      name: 'Mortgage Buyers',
      hours: 'Mon-Fri / 8:00AM - 5:00PM MST',
      corporateCommissionURL:
        'https://ecorp.azcc.gov/BusinessSearch/BusinessInfo?entityNumber=02431091',
      website: 'MortgageBuyersInc.com',
      phone: [
        {
          label: 'Toll Free',
          number: '800-743-2274',
          default: true
        },
        {
          label: 'Work',
          number: '520-577-7471'
        },
        {
          label: 'Fax',
          number: '520-334-1499'
        }
      ],
      logo: '/assets/img/mbi-logo.png',
      slogan:
        'We buy real estate notes of all types, any payment status, and across the country.',
      createAt: {
        seconds: 708678000,
        nanoseconds: 0
      },
      address: [
        {
          label: 'Work',
          location: 'PO Box 12463 Scottsdale, Arizona 85267',
          default: true
        }
      ],
      taxId: '85-4895-564',
      legalName: 'Mortgage Buyers, Inc.',
      url: 'https://www.mortgagebuyersinc.com',
      nickname: 'MBI'
    },
    salesTax: 8.6,
    openssl: {
      pass: '*b#5))%JiyB#fdC---QqP5',
      iv: 'tM@,,F^=Xd2&1}>opGe',
      method: ' AES-256-CBC'
    },
    currency: 'en_US',
    merchants: {
      paypal: {
        logEnabled: false,
        clientid: 'Afsdfdsfsdfsdffffffffffffffffffffffffffffff',
        logLevel: 'FINE',
        clientsecret: 'Afsdfewf-zzzzzzzzzzzzzzzzzzzzzzzzzzzzzz',
        logFile: ''
      },
      authnet: {
        production: false,
        key: '562FjivEjio532',
        login: '5KP25VDrsdjio'
      }
    }
  };

  private websiteConfigs = [
    {
      docID: 'MBI',
      hostnames: [
        'localhost',
        'mortgagebuyersinc.com',
        'www.mortgagebuyersinc.com',
        'frontend.mortgagebuyersinc.com'
      ],
    },
    {
      docID: 'MBI',
      hostnames: [
        'admin.mortgagebuyersinc.com',
        'backend.mortgagebuyersinc.com'
      ],
    }
  ];

  constructor(private http: HttpClient, private windowService: WindowService) {
    const hostname = this.windowService.getHostname();
    this._config = this.websiteConfigs.find(obj => {
      return obj.hostnames.includes(hostname);
    });

    this._config = {...this.globalConfig, ...this._config};
  }

  get config() {
    return this._config;
  }
}
