import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { WebsiteService } from './website.service';


@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(private ws: WebsiteService, private fs: AngularFirestore, private afAuth: AngularFireAuth, private http: HttpClient) { }

  getCompany() {
    //return this.fs.collection('/stocks/AAPL/katoomi-vendor/', ref => ref.where('name', '==', 'Apple Inc');;
    //return this.fs.collection('/stocks/AAPL/');
    return this.fs.doc('/entities/'+this.ws.config.docID);
  }

  createQuote(data) {
    return this.fs.collection('/entities/'+this.ws.config.docID+'/quotes/').add(data);
  }

  createContact(data) {
    return this.fs.collection('/entities/'+this.ws.config.docID+'/contacts/').add(data);
  }
}
