import { Component, OnInit } from '@angular/core';
import { WebsiteService } from '@mbi/mbi';

@Component({
  selector: 'mbi-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  nav: Object;
  subnav: Object;
  alerts: Object;

  constructor(public ws: WebsiteService) {}

  ngOnInit(): void {
    this.alerts = [{
      "type": 'warning', // danger, warning, 
      "text": 'COVID-19 Note: We are still open and ready to serve. Call '+this.ws.config.company.phone[0].number
    }];
  }

  getObjectValues(obj) {
    return Object.values(obj);
  }
}
